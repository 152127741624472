export const toFormData = (object) => {
  const data = new FormData();

  Object.entries(object).forEach(([key, value]) => data.set(key, value));

  return data;
};

export const toFormDataRecurse = (object) => {
  const data = new FormData();

  const setFormData = (data, object) => {
    Object.entries(object).forEach(([key, value]) => {
      if (typeof value !== 'object') {
        data.set(key, value);
      } else {
        data.set(key, setFormData(data, value));
      }
    });
  };

  setFormData(data, object);

  return data;
};

export const toURLSearchParams = (object) => {
  const params = new URLSearchParams();

  Object.entries(object).forEach(([key, value]) => params.append(key, value));

  return params;
};
