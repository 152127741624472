import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Button, Box, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ pageHead }) => {
  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();

  const pathnames = pathname.split('/').filter((x) => x);
  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        justifyContent: 'left'
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {pageHead}
      </Typography>

      <MUIBreadcrumbs
        separator="-"
        sx={{
          ml: 4
        }}
        aria-label="breadcrumb"
      >
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          const linkname = name.replace(/-/g, ' ');

          return isLast ? (
            <Button
              onClick={() => navigate(routeTo)}
              key={name}
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                color: '#181C32',
                fontSize: '0.75rem'
              }}
            >
              {linkname}
            </Button>
          ) : (
            <Button
              key={name}
              onClick={() => navigate(routeTo)}
              sx={{
                textTransform: 'capitalize',
                color: '#8E8EA7',
                fontSize: '0.75rem'
              }}
            >
              {linkname}
            </Button>
          );
        })}
      </MUIBreadcrumbs>
    </Box>
  );
};
Breadcrumbs.propTypes = {
  pageHead: PropTypes.string.isRequired
};
export default Breadcrumbs;
